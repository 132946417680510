.form-picker-full-width {
  width: 100%;
}

.heading-file-uploads {
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  padding-top: 1rem;
  margin-top: 2rem;
  width: 100%;
  display: block;
}

.RaFileInput-dropZone {
  background: rgba(80, 80, 80, 0.05) !important;
}

.resource-icons-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
}

.lodging-type-icons-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
}

.arrow-btn {
  display: inline-flex;
  padding: 2px 8px;
  cursor: pointer;
}

.arrow-btn:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.record-name {
  display: inline-flex;
  margin-left: 10px;
  border-right: rgba(0, 0, 0, 0.12);
}

.disabled-name {
  text-decoration: line-through;
  color: #999;
}

.arrow-dummy {
  display: inline-flex;
  padding: 2px 8px;
  visibility: hidden;
}

.counter {
  position: absolute;
  left: 16px;
  color: #a3a3a3;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
}

.editor-wrapper {
  background-color: #fff;
  padding: 20px;
  margin-top: 30px;
}

#editor-js {
  min-width: 900px;
  width: 100%;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
}

.pagination-item {
  margin-right: 5px;
  display: inline-flex;
  text-align: center;
  padding: 2px 6px;
  cursor: pointer;
}

.current-page {
  font-weight: bold;
  color: #dd4725;
}
