.notes {
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
}

.notes p {
  margin: 2px 0;
}
