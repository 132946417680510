.form-picker-full-width {
  width: 100%;
}

.heading-file-uploads {
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  padding-top: 1rem;
  margin-top: 2rem;
  width: 100%;
  display: block;
}

.RaFileInput-dropZone {
  background: rgba(80, 80, 80, 0.05) !important;
}

.lodging-type-icons-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;
}

.arrow-btn {
  display: inline-flex;
  padding: 2px 8px;
  cursor: pointer;
}

.arrow-btn:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.record-name {
  display: inline-flex;
  margin-left: 10px;
  border-right: rgba(0, 0, 0, 0.12);
}

.disabled-name {
  text-decoration: line-through;
  color: #999;
}

.arrow-dummy {
  display: inline-flex;
  padding: 2px 8px;
  visibility: hidden;
}
