.tabs {
  margin-bottom: 0px;
}

.tabs > button {
  margin-right: 10px;
}

.bulk {
  text-align: right;
  padding: 0px 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.force-from {
  margin-bottom: 10px;
}

.force-to {
  margin-bottom: 10px;
}

.force-picker {
  display: grid !important;
  grid-template-columns: 200px 200px;
  gap: 10px;
}

.force-error {
  color: #b62d2d;
  margin: 0;
  font-size: 14px;
}
