@font-face {
  font-family: "PassengerSans";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../public/fonts/passenger/PassengerSans-Regular.ttf");
}

@font-face {
  font-family: "PassengerSans";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("../public/fonts/passenger/PassengerSans-Semibold.ttf");
}

@font-face {
  font-family: "PassengerSans";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../public/fonts/passenger/PassengerSans-Bold.ttf");
}

@font-face {
  font-family: "PassengerSans";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../public/fonts/passenger/PassengerSans-Extrablack.ttf");
}

.form-text-input-row,
.form-input-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 32px;
}

.form-text-input-row > div {
  flex: 1;
  min-width: 12rem;
}


.MuiDialog-paper .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium {
  top: 0!important;
}