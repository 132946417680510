.arrow-btn {
  display: inline-flex;
  padding: 2px 8px;
  cursor: pointer;
}

.arrow-btn:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.actv-name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
}

.add-actv {
  margin: 20px 0 0;
}

.arrow-dummy {
  display: inline-flex;
  padding: 2px 8px;
  visibility: hidden;
}
