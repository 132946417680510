.wrapper {
  width: 100%;
}

.arrow-btn {
  display: inline-flex;
  padding: 2px 8px;
  cursor: pointer;
}

.arrow-btn.solid {
  background-color: #DD4725;
  color: #fff
}

.arrow-btn:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.arrow-btn.solid:hover {
  background-color: #aaa;
  color: #111;
}

.actv-name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
}

.add-actv {
  margin: 20px 0 0;
}

.arrow-dummy {
  display: inline-flex;
  padding: 2px 8px;
  visibility: hidden;
}

.resource-title {
  margin-left: 10px;
}

.record-name {
  display: inline-flex;
  margin-left: 10px;
  border-right: rgba(0, 0, 0, 0.12);
}

.disabled-name {
  text-decoration: line-through;
  color: #999;
}

.item-cover {
  width: 100%;
  height: 200px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-position: center center;
  background-color: rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat;
}

.actions {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.edit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  right: 0;
  z-index: 5;
  display: block;
  width: 100%;
  height: 100%;
}

.item-cover-disabled {
  opacity: 0.3;
}

.category {
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.catTitle {
  margin: 0 0 10px;
  font-weight: bold;
  font-size: 14px;
}

.inputDesc {
  margin-bottom: 10px;
}

.inputDesc label {
  display: block;
  margin-bottom: 3px;
  font-size: 14px;
}

.inputDesc textarea {
  display: block;
  width: 600px;
  margin-bottom: 4px;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.04);
  font-size: 1rem;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  padding: 5px 10px;
  font-family: sans-serif;
}

.inputDesc span {
  display: block;
  font-size: 12px;
}

.inputToggleWrapper > div:first-child {
  margin-right: 20px;
}

.toggleLayout {
  display: flex;
  align-items: center;
}
