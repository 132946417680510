.wrapper-rooms {
  width: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  margin: 20px 0 0;
}

.collapse-rooms {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px 20px;
}

.rooms-list {
  padding-left: 30px;
}
