.form-picker-full-width {
  width: 100%;
}

.heading-file-uploads,
.heading-activities,
.heading-lodging-types {
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  padding-top: 1rem;
  margin-top: 2rem;
  width: 100%;
  display: block;
}

.heading-tab-section {
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  width: 100%;
  display: block;
}

.RaFileInput-dropZone {
  background: rgba(80, 80, 80, 0.05) !important;
}

.property-badges-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  width: 100%;
}

.property-badges-grid > .ra-input {
  min-width: 21rem;
  flex: 1;
}

.badge {
  background-color: #c8d0c8;
  color: #191919;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  padding: 2px 6px;
  display: inline-flex;
  position: relative;
  top: -1px;
  &.live { 
    background-color: #19988B;
    color: white;
  }
}

.tabPanel {
  width: 100%;
  flex-grow: 2;
}
