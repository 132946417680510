.review-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  transition: all ease-in 300ms;
  font-size: 14px;
}

.review-item p {
  margin: 0;
}

.review-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.review-top {
  display: flex;
  margin-bottom: 10px;
}

.review-item .check, .review-item .status, .review-item .rating, .review-item .author, .review-item .date {
  margin-right: 10px;
  display: block;
}

.review-item .status {
  color: rgba(0, 0, 0, 0.5)
}

.review-item .rating {
  font-weight: bold;
  letter-spacing: 1.5px;
}

.review-bottom {
  margin-left: 30px;
}

.review-item .review-body {
  margin-bottom: 10px;
}
