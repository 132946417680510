.wrapper {
  width: 100%;
}

.wrapper .MuiToolbar-root {
  z-index: 1000;
}

.wrapper .MuiToolbar-root > button.Mui-disabled {
  background-color: #e4e4e4;
}

.arrow-btn {
  display: inline-flex;
  padding: 2px 8px;
  cursor: pointer;
}

.arrow-btn.solid.gallery {
  background-color: transparent;
  color: #d6d6d6;
  padding: 0;
}

.arrow-btn:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.arrow-btn.solid.gallery:hover {
  color: #525252;
  background-color: transparent;
  padding: 0 !important;
}
.arrow-btn.solid.gallery.remove {
  background-color: transparent;
  color: #ff3b0a;
  padding: 0 !important;
}
.arrow-btn.solid.gallery.remove:hover {
  background-color: transparent;
  color: #c32400;
  /* opacity: 0.5; */
  padding: 0 !important;
}

.actv-name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
}

.heading {
  font-size: 18px !important;
}

.add-actv {
  margin: 20px 0 0;
}

.arrow-dummy {
  display: inline-flex;
  padding: 2px 8px;
  visibility: hidden;
}

.resource-title {
  margin-left: 10px;
}

.record-name {
  display: inline-flex;
  margin-left: 10px;
  border-right: rgba(0, 0, 0, 0.12);
}

.disabled-name {
  text-decoration: line-through;
  color: #999;
}

.item-cover {
  width: 329.67px !important;
  height: 185.82px !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: contain;
  background-position: center center;
  background-color: rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat;
}

.actions {
  position: absolute;
  left: 0;
  top: -24px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.item-container {
  max-width: 329.67px !important;
}

.edit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}
